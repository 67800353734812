<template>
    <transition name="fade" >
        <div id="cp-loading" class="preloader ocultar">
            <div class="logo" ></div>
            <p>Procesando</p>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Loading',
    props : {
        visible: { type: Boolean, default: true },
    },
    setup(props) {

    }
    
}
</script>
