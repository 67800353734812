<template>
  <div class="full-heigth">
    <loading :visible="view_loading" />
    <div class="contenedor-principal">
      <div class="row contenedor-secundario">
        <router-view />
        <div class="col-md-8 panel-formulario">
          <h2 class="imagen-login"></h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Login from "@/components/auth/Login";
import loading from "@/components/Loading";
import { ref, onMounted } from "vue";

export default {
  name: "Pagina login",
  setup() {
    const view_loading = ref(true);

    const ocultarLoading = () => {
      view_loading.value = false;
    };

    onMounted(ocultarLoading);

    return {
      view_loading,
    };
  },
  components: {
    Login,
    loading,
  },
};
</script>

<style scoped>
.contenedor-row {
  width: 80%;
}
.contenedor-principal {
  width: 100%;
  height: 100%;
  background-color: var(--ng-background);
  display: flex;
  justify-content: center;
}
.contenedor-secundario {
  width: 100% !important;
}

.panel-formulario {
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding: 0 2rem 0 2rem;*/
}

.imagen-logo {
  width: 100%;
  height: 7rem;
  background-image: url(../../assets/images/img_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.imagen-login {
  width: 80%;
  height: 80%;
  background-image: url(../../assets/images/ic_image_login.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bienvenido {
  text-align: left;
  font-weight: bold;
  font-size: 1.6em;
  margin-top: 1.6em;
}

p {
  font-size: 0.9em;
  text-align: justify;
}

.margen-superior {
  margin-top: 2rem;
}

.container-input-usuario {
  width: 100%;
  height: 2.6rem;
  background: var(--ng-blanco);
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  outline: none;
  list-style: none;
  margin-bottom: 1rem;
  box-shadow: 12px 11px 21px -19px rgb(0, 0, 0, 1);
}

.container-input-usuario > input {
  border: 1px solid transparent;
  color: rgba(var(--vs-text), 1);
  border-radius: inherit;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 7px 13px 7px 10px;
  width: 100%;
  padding-left: 46px;
  outline: none;
  list-style: none;
  background: var(--ng-blanco);
  font-size: 0.86em;
}

.container-input-usuario > span {
  position: absolute;
  right: auto;
  width: 2.9em;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border-radius: inherit;
  background: rgba(var(--vs-gray-2), 1);
  pointer-events: none;
  left: 0;
  outline: none;
  list-style: none;
  transition: all 0.2s ease-out;
}

.container-input-usuario > span > i {
  font-size: 1.2em;
}
.recordar-clave {
  font-size: 0.79em;
  cursor: pointer;
}

.input-content-ng1 {
  width: 100%;
  height: 2.6rem;
  background: #f4f7f8;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  outline: none;
  list-style: none;
  margin-bottom: 1rem;
}

.ng-input1 {
  border: 1px solid transparent;
  color: rgba(var(--vs-text), 1);
  border-radius: inherit;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 7px 13px 7px 10px;
  padding-left: 10px;
  width: 100%;
  padding-left: 38px;
  outline: none;
  list-style: none;
  background: #f4f7f8;
}

.ng-input__icon {
  position: absolute;
  right: auto;
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  box-shadow: 12px 0 10px -10px rgba(0, 0, 0, var(--vs-shadow-opacity));
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border-radius: inherit;
  background: rgba(var(--vs-gray-2), 1);
  pointer-events: none;
  left: 0;
  outline: none;
  list-style: none;

  transition: all 0.2s ease-out;
}
.ng-input1:focus ~ .ng-input__icon {
  cursor: pointer;
  transform: translateY(-3px) translateX(-6px) translateZ(14px);
  box-shadow: 12px 6px 10px -11px rgba(0, 0, 0, 0.2);
  /*box-shadow: 0 1px 7px rgba(255,255,255,0.1);
        background: rgba(37,100,255,.15);*/
  background: #f7fafb;
}

.ng-input1:focus {
  border-bottom: 2px solid #0d6efd69;
}

.ng-input1:focus ~ .ng-input__icon > i {
  color: #0d6efd;
}

.formulario-login {
  margin-top: 3rem;
}
</style>
